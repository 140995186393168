@use "sass:math";
@import "assets/styles/colors.scss";
// @import "ng-zorro-antd/ng-zorro-antd.css";
@import "swiper/swiper-bundle.css";
@import "@ctrl/ngx-emoji-mart/picker";

html,
body {
  height: 100%;
}
@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Regular.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: Lato, sans-serif; // overflow: hidden;
  overflow: hidden;
}

html,
body {
  height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Blast Email Styling
.step-container {
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 10px;
  }

  .ant-steps-item-title {
    font-size: 13px;
  }
}

.text-center {
  text-align: center;
}

.cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.1);
}

.onboardingBackdrop {
  background: rgba(0, 0, 0, 0.8);
}

.large-spinner {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  position: fixed !important;
  z-index: 1000;
}

.up-action-button {
  background-color: #2fd288;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

.up-action-button[disabled] {
  background-color: rgba(grayscale(#2fd288), 0.5);
  color: darkgray !important;
  cursor: not-allowed;
}

.up-cancel-button {
  text-decoration: underline !important;
}

h1.title {
  font-size: 24px;
  font-weight: bold;
  color: #393853;
}

.section-title {
  font-weight: 300;
  margin-bottom: 0;
}

.section-title-small {
  font-size: 19px;
  font-weight: 600;
}

.page-title {
  margin-top: 34px;
}

.page-container {
  padding: 2vw;
  padding-left: 24px;
  padding-top: 10px; // height: 100%;
  // overflow: scroll;
  box-sizing: border-box; // padding-bottom: 50px;
  // margin-bottom: 50px;
}

.delete-button {
  // background-color: #B80f0a;
  color: #b80f0a;
}

.invalid {
  color: red;
}

app-card-button {
  height: 192px !important;
  width: 168px !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Lato;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (math.div($height, $width)) * 100%;
  }

  >.internal-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.mnav-dropdown {
  .amml-icon {
    margin-left: 8px;
    color: #00e185;
  }

  .label {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #fff;
    margin-left: 4px;
  }
}

.market-reports-details {
  text-align: center;
  padding: 10px 0;

  .report {
    .report-title {
      font-size: 24px;
      font-weight: bold;
      margin: -6px 0 0 0;
      display: inline-block;
    }

    .edit-container {
      display: inline-block;
      border: 1px solid #0070f6;
      background-color: #0070f6;
      border-radius: 4px;
      margin-left: 15px;
      width: 26.1px;
      height: 26.1px;
      line-height: 24px;

      a {
        padding: 0;
        vertical-align: middle;
        line-height: 24px;
        min-width: unset;
      }
    }

    .delete-container {
      display: inline-block;
      border: 1px solid #ff4d4f;
      background-color: #ff4d4f;
      border-radius: 4px;
      margin-left: 15px;
      width: 26.1px;
      height: 26.1px;
      line-height: 24px;
      cursor: pointer;

      button {
        padding: 0;
        vertical-align: middle;
        line-height: 24px;
        min-width: unset;
      }
    }
  }

  .info {
    .info-item {
      font-size: 14px;
      margin-bottom: 15px; // min-width: 90px;
    }
  }

  .image-container {
    // height: 85px;
    margin-bottom: 15px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.transition {
  transition: all 0.4s ease-in-out;
}

.carousel {
  .content {
    display: flex;

    .item {
      width: 100%;
      display: block;

      .img {
        width: 100%;
        display: block;
        background-size: cover;
        background-position: center;
        height: 0;
        padding-bottom: 50%;
      }
    }
  }

  .item {
    width: 100%;
    display: block;

    .img {
      width: 100%;
      display: block;
      background-size: cover;
      background-position: center;
      height: 0;
      padding-bottom: 50%;
    }
  }

  .ball {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: black;
    border: 2px solid;
    opacity: 0.5;

    &.visible {
      opacity: 1;
    }
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: #ff5252;
  }

  .click-area {
    width: 50px;
    text-align: center;

    i {
      font-size: 3em;
    }
  }
}

// New Design Implmentation
.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 12px;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #d9d9d9;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: unset;
  right: 8px;
  top: 40%;
  font-size: 16px;
}

.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: none;
}

.collapse-title {
  font-size: 18px;
}

.home-carousel {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.is_desktop {
  display: block;
  background-color: #fbfbfd;
}

.is_mobile {
  display: none;
}

nz-content {
  padding: 2vw;
  padding: 10px 2vw 68px;
  padding-top: 10px;
  box-sizing: border-box;
  background-color: #F6F9FC;
}

.nz-mb-0 {
  margin-bottom: 0;
}

.nz-pb-0 {
  padding-bottom: 0;
}

.nz-mb-15 {
  margin-bottom: 15px;
}

.nz-mlr-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.nz-plr-15 {
  padding-left: 27.32px;
  padding-right: 27.32px;
}

.color-picker-selector {
  margin-top: 15px;
}

.branding-content {
  .page-container {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 420px) {
  .is_desktop {
    display: none;
  }

  .is_mobile {
    display: block;
  }

  .home-carousel {
    height: 120px;
  }

  h1.title {
    font-size: 1.3em;
  }

  .section-title {
    font-size: 18px;
    font-weight: 500;
  }

  .m-0 {
    margin: 0 !important;
  }

  .m-l-25 {
    margin-left: 25px !important;
  }

  .m-l-20 {
    margin-left: 20px !important;
  }

  .image-card {
    width: 146px;
  }

  .edit-container {
    margin: 0 15px 3px 15px !important;
    width: 22.1px !important;
    height: 22.1px !important;
    line-height: 18px !important;
  }
}

@media only screen and (max-width: 420px) {
  .is_desktop {
    display: none;
  }

  .is_mobile {
    display: block;
  }

  .home-carousel {
    height: 120px;
  }

  h1.title {
    font-size: 1.3em;
  }

  .section-title {
    font-size: 18px;
    font-weight: 500;
  }

  .m-0 {
    margin: 0 !important;
  }

  .m-l-25 {
    margin-left: 25px !important;
  }

  .m-l-20 {
    margin-left: 20px !important;
  }

  .image-card {
    width: 146px;
  }

  .edit-container {
    margin: 0 15px 3px 15px !important;
    width: 22.1px !important;
    height: 22.1px !important;
    line-height: 18px !important;
  }
}

.on-boarding {
  cursor: pointer;

  &.ant-steps-item-finish {
    .ant-steps-item-content {
      opacity: 0.4;
    }

    cursor: default;
  }

  // &.ant-steps-item-process {
  //   .ant-steps-item-content {
  //     .ant-steps-item-description {
  //       color: #000000 !important;
  //     }
  //   }
  // }
  &.locked {
    cursor: no-drop;

    .ant-steps-item-description {
      color: #00000073 !important;
    }

    .ant-steps-item-title {
      color: #00000073 !important;
    }
  }

  &:hover {
    &.ant-steps-item-process {
      .ant-steps-item-title {
        text-decoration: underline;
      }
    }

    &.ant-steps-item-wait {
      .ant-steps-item-title {
        text-decoration: underline;
      }
    }
  }

  .ant-steps-item-title {
    font-size: 24px !important;
    line-height: normal !important;
    color: #000000 !important;
    font-weight: bold;
  }

  .ant-steps-item-description {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: normal !important;
    color: #00000080 !important;
    margin-top: 16px !important;
    margin-bottom: 35px !important;
  }

  .ant-progress-line {
    margin-bottom: 44px;
    cursor: default !important;
  }
}

.Rectangle {
  width: 1440px;
  height: 80px;
  background-color: #0070f7;
}

.default-link {
  cursor: default !important;
}

#webWidget,
#launcher {
  left: unset !important;
  right: 0px !important; // display: none !important;
  bottom: 35px !important;
}

.ant-layout {
  background: #fbfbfd !important;
}

.ant-layout-sider-zero-width-trigger {
  display: none !important;
}

.ant-layout-sider-trigger {
  position: absolute !important;
  top: 67px !important;
  right: -16px !important;
  margin: 0 !important;
  padding: 5px 7px !important;
  line-height: normal !important;
  height: auto !important;
  width: auto !important;
  bottom: auto !important;
  z-index: 2 !important;
  display: flex;
  color: #858C97 !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #E3E9EF);
  background: var(--White, #FFF) !important;
  box-shadow: 0px 6px 12px -2px rgba(208, 208, 196, 0.24);
}

.side-nav-item {
  font-size: 14px;
  font-weight: bold;
  color: #737884 !important;

  svg {
    vertical-align: middle;
    width: 24px;
    height: 24px;
  }
}

.ant-layout-sider {
  background-color: #fff !important;
}

.ant-layout-header {
  padding: 0 15px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  // background-color: #0070f6;
}

.ant-devider {
  background: rgba(0, 0, 0, 0.12);
}

.spiner-center {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.ant-btn {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
}

.swiper-button-prev {
  width: 55px;
  height: 55px;
  border-radius: 6px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c3ZnanM9Imh0dHA6Ly9zdmdqcy5jb20vc3ZnanMiIHZlcnNpb249IjEuMSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA0NTEuODQ2IDQ1MS44NDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiIGNsYXNzPSIiPjxnIHRyYW5zZm9ybT0ibWF0cml4KC0xLDAsMCwxLDQ1MS44NDY0NjYwNjQ0NTMxLDApIj4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Ik0zNDUuNDQxLDI0OC4yOTJMMTUxLjE1NCw0NDIuNTczYy0xMi4zNTksMTIuMzY1LTMyLjM5NywxMi4zNjUtNDQuNzUsMGMtMTIuMzU0LTEyLjM1NC0xMi4zNTQtMzIuMzkxLDAtNDQuNzQ0ICAgTDI3OC4zMTgsMjI1LjkyTDEwNi40MDksNTQuMDE3Yy0xMi4zNTQtMTIuMzU5LTEyLjM1NC0zMi4zOTQsMC00NC43NDhjMTIuMzU0LTEyLjM1OSwzMi4zOTEtMTIuMzU5LDQ0Ljc1LDBsMTk0LjI4NywxOTQuMjg0ICAgYzYuMTc3LDYuMTgsOS4yNjIsMTQuMjcxLDkuMjYyLDIyLjM2NkMzNTQuNzA4LDIzNC4wMTgsMzUxLjYxNywyNDIuMTE1LDM0NS40NDEsMjQ4LjI5MnoiIGZpbGw9IiMwMTcwZjYiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiIGNsYXNzPSIiLz4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8L2c+PC9zdmc+Cg==");
  left: 30px;
  background-size: 55px;
  background-repeat: no-repeat;
  top: 42%;
  left: 0;
}

.swiper-button-next {
  width: 55px;
  height: 55px;
  border-radius: 6px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c3ZnanM9Imh0dHA6Ly9zdmdqcy5jb20vc3ZnanMiIHZlcnNpb249IjEuMSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA0NTEuODQ2IDQ1MS44NDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiIGNsYXNzPSIiPjxnPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0iTTM0NS40NDEsMjQ4LjI5MkwxNTEuMTU0LDQ0Mi41NzNjLTEyLjM1OSwxMi4zNjUtMzIuMzk3LDEyLjM2NS00NC43NSwwYy0xMi4zNTQtMTIuMzU0LTEyLjM1NC0zMi4zOTEsMC00NC43NDQgICBMMjc4LjMxOCwyMjUuOTJMMTA2LjQwOSw1NC4wMTdjLTEyLjM1NC0xMi4zNTktMTIuMzU0LTMyLjM5NCwwLTQ0Ljc0OGMxMi4zNTQtMTIuMzU5LDMyLjM5MS0xMi4zNTksNDQuNzUsMGwxOTQuMjg3LDE5NC4yODQgICBjNi4xNzcsNi4xOCw5LjI2MiwxNC4yNzEsOS4yNjIsMjIuMzY2QzM1NC43MDgsMjM0LjAxOCwzNTEuNjE3LDI0Mi4xMTUsMzQ1LjQ0MSwyNDguMjkyeiIgZmlsbD0iIzAxNzBmNiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgc3R5bGU9IiIgY2xhc3M9IiIvPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjwvZz48L3N2Zz4K");
  background-size: 55px;
  right: 35px;
  background-repeat: no-repeat;
  top: 42%;
  right: 0;
}

nz-select {
  min-width: 200px;
}

.error {
  color: red;

  &.left {
    float: left;
    font-size: 14px;
    line-height: 1.5;
  }

  &.right {
    float: right;
    font-size: 14px;
    line-height: 1.5;
  }
}

.space-right {
  margin-right: 16px;
}

.space-left {
  margin-left: 16px;
}

.upgrade-steps {
  .ant-steps-horizontal {
    height: 72px;

    nz-step {
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-size: 14px !important;
          font-weight: 500 !important;
          font-family: Lato;
        }
      }
    }
  }

  cursor: pointer;
}

.ant-table-body {
  font-family: Lato;
}

.card-radio {
  label {
    .ant-radio {
      .ant-radio-inner {
        width: 24px !important;
        height: 24px !important;
      }

      .ant-radio-inner::after {
        top: 6px !important;
        left: 6px;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.content-card {
  font-family: Lato;
  color: rgba(0, 0, 0, 0.87);

  .ant-card-body {
    padding: 16px;
  }

  .ant-card-head {
    min-height: 0;
    border-bottom: none;

    .ant-card-head-wrapper {
      .ant-card-extra {
        padding: 8px 0 0 0;
      }
    }
  }
}

.img-card {
  nz-card-meta {
    .ant-card-meta-avatar {
      .ant-avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}

.ant-card-meta-title {
  font-size: 20px;
  margin-bottom: 0 !important;
}

.ant-card-meta-description {
  margin-bottom: 16px;
}

.spinner {
  text-align: center; // background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.customer-form {
  nz-form-item {
    margin: 0;
  }
}

.edit-icon {
  color: white;
  font-size: 16px;
}

.content-stepper {
  .ant-steps {
    margin: 0 0 16px 0;

    .ant-steps-item-finish {
      cursor: pointer;
    }
  }
}

.ant-card-meta-title {
  // margin-bottom: 8px !important;
}

.sidebar-divider {
  width: 80%;
  min-width: 80%;
  margin: 24px;
}

.ant-popover-inner-content {
  p:last-child {
    margin-bottom: 0;
  }
}

.swiper-cc {
  .swiper-container {
    padding-bottom: 20px;
  }
}

.swiper-c {
  .swiper-container {
    padding-bottom: 20px;

    .swiper-slide {
      text-align: center;
      font-size: 18px; // background: #fff;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
}

.swiper-button-c-next {
  margin-right: -10px;
}

.swiper-button-c-prev {
  margin-left: -20px;
}

.ant-input-disabled {
  pointer-events: none;
}

.no-content {
  margin-top: 140px;
}

.ant-menu-item {
  line-height: 33px !important;
}

@media only screen and (max-width: 768px) {
  .nav-dots {
    font-size: 32px;
    margin-top: 17px;
  }

  .company-logo {
    img {
      width: 114px;
      margin-left: 0 !important;
    }
  }

  .logo-nav {
    background: #fbfbfd !important;
    margin-top: -88px !important;
    text-align: center;

    .company-logo {
      img {
        width: 150px;
        margin-left: 0;
      }
    }
  }

  .bread-container {
    padding: 15px !important;
    background: #fbfbfd;
  }

  .no-content {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.margin-image-card {
  margin-top: 10px;
}

.nf-list {
  h4 {
    font-size: 10px;
    //font-weight: bold;
    //font-style: normal;
    //font-stretch: normal;
    //line-height: normal;
    //letter-spacing: normal;
    color: #0070f7;
  }

  .ant-list-item-meta-description {
    font-size: 13px;
    color: #000000; //white-space: nowrap;
    width: 310px; //overflow: hidden;
    //text-overflow: ellipsis;
  }
}

.nt-list {
  h4 {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0070f7;
  }

  .ant-list-item-meta-description {
    font-size: 16px;
    color: #000000; //font-weight: 500;
    font-weight: 600;

    span {
      font-size: 15px; //font-weight: 700;
      //color: darkgrey;
    }
  }
}

.st-list {
  .ant-list-item-meta-content {
    h4 {
      font-size: 21px;
      color: #000000;
      font-weight: bold;
    }
  }
}

.sub-list {
  .ant-list-item-meta-content {
    h4 {
      font-size: 15px;
      color: #000000;
      font-weight: bold;
    }
  }
}

.setting-selected {
  color: #1890ff !important;
}

.ant-subscription-item {

  .ant-list-item-meta,
  .ant-list-item-extra-wrap {

    // margin-left: 30px;
    .ant-list-item-main {
      float: right; //width: 90%;
    }

    .ant-list-item-extra {
      float: left;
      margin-right: 15px;
    }
  }
}

.ant-modal-close-x {
  font-size: 24px;
}

.ant-layout-sider-collapsed {
  .ant-layout-sider-children {
    .logo {
      .company-logo {
        padding-left: 5px;
      }
    }
  }
}

.ant-menu-inline-collapsed>.user-icon {
  padding: 0px 20px !important;

  .social-selection {
    li {
      label {
        .ant-checkbox {
          padding-right: 8px;
        }
      }
    }
  }
}

.top-menu {
  .ant-menu-item {
    // padding: 0 32px 0 26px !important;
    color: #576071 !important;
    border-radius: 5px;
  }
}

.ant-modal-content {
  border-radius: 10px !important;
}

.registration-tabs {
  .ant-tabs {
    .ant-tabs-bar {
      border: 1px solid #d9d9d9;
      border-radius: 4px;

      .ant-tabs-nav-container {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-scroll {
            .ant-tabs-nav {
              div {
                .ant-tabs-tab {
                  border-right: 1px solid #d9d9d9;
                  margin-right: 0;
                  padding-right: 32px;
                  width: 240px;
                  max-width: 300px;
                  min-width: 150px;
                  padding-left: 8px;
                }

                .ant-tabs-tab:last-of-type {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.section-title {
  i {
    color: #0070f7;
  }
}

.customer-form nz-form-item {
  width: 48%;
}

.customer-form-realtor nz-form-item {
  width: 29%;
}

.top-row-padding {
  padding-top: 8px;
}

.bottom-row-padding {
  padding-bottom: 8px;
}

.create-btn-padding {
  //padding-left: 30px;
}

.grey-icon {
  color: #555555;
}

.swiper-button-next:after {
  content: none;
}

.swiper-button-prev:after {
  content: none;
}

.ant-form-item-control,
nz-form-control {
  margin: 0 0 16px !important;
}

.ant-form-item .ant-form-item-label>label {
  @media (max-width: 575px) {
    margin-right: 4px;
  }
}

.listing-form {
  div {
    nz-form-item {
      nz-form-label {
        text-align: left;
      }
    }
  }
}

.text-left {
  text-align: left !important;
}

.search-mls {
  max-width: 258px;
  min-width: 258px;
}

.selected-item,
.top-menu .ant-menu-item:hover {
  background-color:  #F6F9FC !important;
  color: #15171B !important;
  border-radius: 5px;

  span {
    color: #15171B !important;
  }
}

._pendo-launcher-badge_ {
  display: none;
}

.ant-menu-item {
  width: calc(100% + 3px) !important;
}

#drift-widget-container {
  z-index: 0 !important;
}

.occupancy-number {
  width: 100% !important;
}

.ant-picker.ant-picker-disabled {
  background: transparent !important;
}

.ant-list-split .ant-list-item:last-child {
  border-bottom: none !important;
}

center {
  max-width: 580px;
}

// .ant-table-selection-extra {
//   position: relative !important;
// }

// .ant-table-selection-extra {
//   position: absolute;
//   top: 0;
//   right: 18px;
//   cursor: pointer;
//   transition: all .3s;
//   z-index: 999;
// }
.ant-menu-submenu-title {
  .anticon {
    font-size: 22px !important;
  }
}

.ant-menu-item-selected {
  .anticon {
    color: #F6F9FC !important;
  }
}

.top-menu {
  .sub-menu-title {
    //color: #333 !important;
    //font-size: 14px !important;
    //font-weight: bold !important;
    //background: #fff;

    padding-left: 5px !important;

    color: #576071 !important;
    //font-feature-settings: 'liga' off, 'clig' off;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 20.8px */

    &:hover {
      .ant-menu-submenu-title {
        color: #576071 !important;
      }

      .ant-menu-submenu-arrow {

        &:before,
        &:after {
          //background-color: #333;
        }
      }
    }

    .ant-menu-submenu-arrow {

      &:before,
      &:after {
        background-color: #333 !important;
      }
    }

    .ant-menu-submenu-arrow {
      color: #333;
    }

    .ant-menu-sub {
      background: #fff !important;
      color: #333 !important;

      ul {
        li {
          padding-left: 36px !important;
        }
      }
    }
  }
}

.margin-bottom-zero {
  margin-bottom: 0;
}

.ant-skeleton-image {
  width: 140px !important;
}

.margin-zero {
  margin: 0 !important;
}

.caption-input-area {
  height: 190px !important;
}

.align-right {
  text-align: right;
}

.sub-text>label::after {
  content: none !important;
}

.show-password {
  cursor: pointer;
  color: #0170f6;
}

.overflow-visible {
  overflow: visible !important;
}

.image-card {
  button {
    display: none !important;
  }

  &:hover {
    overflow: visible !important;

    button {
      display: inline !important;
    }
  }
}

.single-data-snap {
  .ant-radio {
    .ant-radio-inner {
      border: 2px solid #b5b5b5;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border: 2px solid #1890ff;
      width: 18px;
      height: 18px;
    }
  }
}

.pull-right {
  float: right;
}

.data-explainer {
  font-size: 17px;
  padding-bottom: 31px;
  font-weight: 405;
}

.selectable:hover {
  background-color: #2196f347 !important;
}

// @media (max-height: 500px) {
//   .scrollable-list {
//     height: 38vh;
//     overflow-y: auto;
//     overflow-x: hidden;
//   }
// }

// @media (max-height: 730px) {
//   .scrollable-list {
//     height: 50vh;
//     overflow-y: auto;
//     overflow-x: hidden;
//   }
// }

.d-block {
  display: block !important;
}

.ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.caption-input-area {
  max-width: 100%;
  max-height: 100px;
  min-height: 100px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all .3s, height 0s;
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.data-explainer::after {
  display: none;
  content: none;
}

.data-explainer {
  font-size: 17px;
  padding-bottom: 31px;
  font-weight: 405;
}

.cursor-pointer {
  cursor: pointer;
}

.mls-banner {
  width: 75%;

  .ant-alert {
    padding: 8px 15px 15px 37px !important;
  }
}

#drift-frame-chat {
  width: 50% !important;
  right: 20% !important;
  top: 5% !important;
  min-width: 50% !important;
}

#drift-frame-controller {
  z-index: -88 !important;
  display: none;
}

.caption-input-area {
  max-width: 100%;
  max-height: 100px;
  min-height: 100px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.notice {
  padding: 15px 15px 15px 24px;
  background-color: #fff9d7;
  border: 1px solid #ffe58f;
  border-radius: 5px;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}

.text-right {
  text-align: right;
}

.beacon {
  box-shadow: none !important;
}

.apcl-left {
  // left: 15px !important;
  // top: auto !important;
  bottom: 15px !important;
  // z-index: 1;
  // height: 52px !important;
}

.appcues {
  appcues-checklists {
    .apcl-left {
      iframe {
        html {
          body {
            .checklist {
              .beacon {
                box-shadow: none !important;
              }
            }
          }
        }
      }
    }
  }
}

.calender-table-sa-ui {
  background: white;
  padding: 10px;
  table.ant-picker-content {
    ul.events {
      .ant-btn {
        width: 100%;
        display: flex;
        align-items: center;
        color: rgb(255 255 255) !important;
        background: rgb(82, 196, 26);
        border-radius: 4px;

        &>span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100px;
        }
      }
    }
  }
}

.ant-picker-calendar-full {
  .ant-picker-panel {
    .ant-picker-calendar-date.has-post {
      border-color: #1890ff;
    }
  }
}

.cdk-overlay-container {
  .ant-btn.ant-btn-dangerous {
    border-color: #ff7875;
    background-color: #ff7875;
    color: #fff;
  }
}

.cdk-overlay-container {
  .ant-popover-inner {
    border-radius: 5px;
    border-top: 3px solid #f97775;
  }
}

p.tb-border {
  padding: 6px 0;
  border-top: 1px solid #f3f1f1;
  margin: 4px 0;
}

.platform-image {
  width: 20px;
  height: 20px;
  margin-right: 0px;
}

.content-event {
  height: 35px;
}

.fc-event-main {
  margin-top: 4px;
  text-align: center;
  padding-left: 8px;
}

.event-title {
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  white-space: nowrap;      /* Prevent text from wrapping to the next line */
  overflow: hidden;         /* Hide the overflowed content */
  text-overflow: ellipsis;  /* Show "..." when text overflows */
  //width: 200px;
}

.fc-daygrid-day-events {
  padding: 0 5px;
  overflow: scroll;
}

.main-container .ant-layout-content {
  padding-top: 4px;
}

.create-content-btn {
  margin-left: 20px;
}

.fc {
  .fc-toolbar-chunk {
    display: flex;
    margin-top: 3px;
  }

  button.fc-prev-button,
  button.fc-next-button,
  button.fc-today-button {
    text-transform: capitalize;
    color: #434A57;
    display: flex;
    width: 75px;
    height: 40px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--Gray-300, #E3E9EF);
    background: var(--White, #FFF);

    &:active {
      display: flex;
      width: 75px;
      height: 40px;
      padding: 14px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px 0px 0px 5px;
      border: 1px solid var(--Gray-300, #E3E9EF);
      color: #121519;
      background: var(--Gray-200, #EDF1F5);
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .fc-button-group {
    //background-color: White;
    //height: 38px;
    //padding: 5px;
    //border-radius: 6px;
    //margin-right: 15px;

    button.fc-button {
      text-transform: capitalize;
      color: #434A57;
      display: flex;
      width: 75px;
      height: 40px;
      padding: 14px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid var(--Gray-300, #E3E9EF);
      background: var(--White, #FFF);

      &:active {
        background-color: #fff;
        color: #434A57;
        box-shadow: none !important;
        margin-top: 0 !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }

    button.fc-button.fc-button-active {
      display: flex;
      width: 75px;
      height: 40px;
      padding: 14px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px 0px 0px 5px;
      border: 1px solid var(--Gray-300, #E3E9EF);
      color: #121519;
      background: var(--Gray-200, #EDF1F5);
    }
  }
}

.fc-toolbar-title {
  //font-size: 14px !important;
  margin-top: 9px !important;
  max-width: 122px;
  min-width: 122px;
  margin-left: 0 !important;
  text-align: center;

  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Lato !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important; /* 27px */
}

@media screen and (max-width: 945px) {
  .calender-table-sa-ui {
    margin-top: 25px;
  }

  .fc {
    .fc-button-group {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
      height: 45px;
    }

    .fc-toolbar-chunk {
      display: flex;
      margin-top: 3px;
      flex-wrap: wrap;
      //justify-content: flex-end;
    }
    .fc-toolbar{
      justify-content: unset;
    }
  }
}

.nf-list .ant-list-item-meta-description {
  width: 100%;
  display: flex;
}

div.noti-text {
  float: none;
  width: calc(100% - 30px) !important;
  color: #333;
}

div.noti-icon {
  float: none;
  color: #333;
}

.notification-dropdown .ant-list-item {
  margin: 5px;
  border-radius: 5px;
}

.notification-dropdown nz-list-item.ant-list-item.active.un-read {
  background-color: #0070f7;
}

.notification-dropdown nz-list-item.ant-list-item {
  background-color: #fbfbfd;
  padding: 10px;

  &:hover {
    background-color: #0070f7;

    div.noti-text {
      color: #fff;
    }

    div.noti-icon {
      color: #fff;
    }
  }
}

.notification-dropdown nz-list-item.ant-list-item.active.un-read div.noti-text,
.notification-dropdown nz-list-item.ant-list-item.active.un-read div.noti-icon {
  color: #fff;
}

.p15 {
  padding: 15px;
}

.m-0 {
  margin-bottom: 5px;
  line-height: normal;
  color: #333;
  margin-left: 18px;
}

.align-items-center {
  align-items: center;
}

@media screen and (min-width: 946px) and (max-width: 1400px) {
  .calender-table-sa-ui {
    margin-top: 25px;
  }

  .fc {
    .fc-button-group {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
      height: 45px;
    }

    .fc-toolbar-chunk {
      display: flex;
      margin-top: 3px;
      flex-wrap: wrap;
      //justify-content: flex-end;
    }
  }
}

app-content-calender.ng-star-inserted {
  position: relative;
  z-index: 1;
}

.fc-timegrid-body {
  display: none;
}

.dropmenu-icon{
  float: right;
  margin-top: 12px;
}

.error-input {
  border: 1px solid #ff4d4f;
  background-color: #fff;
}
.fc .fc-col-header-cell{
  padding: 12px;
  border: 1px solid var(--Gray-300, #E3E9EF);
  background: var(--Gray-100, #F6F9FC);
}
.fc-daygrid-event {
  max-height: 100%;  /* Ensure event height stays within the day box */
  white-space: nowrap;  /* Prevent text from wrapping */
  overflow: hidden;  /* Hide any overflow content */
}
.fc-col-header-cell-cushion{
  color: var(--Gray-800, var(--Gray-800, #434A57));
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 22.5px */
  color: #434A57;
}
ant-menu-inline .ant-menu-submenu, .ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu {
  padding-bottom: .02px;
  margin-left: 5px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 60px !important;
}
